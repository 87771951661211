import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios';
import { renderToString } from 'react-dom/server';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import './component_styling/customStyles.css';
import { API_URL } from './config';
Quill.register('modules/imageResize', ImageResize);
const Font = Quill.import('formats/font');
Font.whitelist = ['sans-serif'];
Quill.register(Font, true);

const theme = createTheme({
  typography: {
    fontFamily: 'sans-serif',
    body1: {
      fontSize: '14px',
    },
  },
  palette: {
    primary: { main: '#1976d2' },
    background: { paper: '#ffffff', default: '#f2f4fa' },
  },
});

const EditorContainer = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#f2f4fa',
  padding: theme.spacing(2),
  marginTop: theme.spacing(3),
  width: '70%',
}));

const RichTextHTMLViewer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const quillRef = useRef(null);

  const [htmlContent, setHtmlContent] = useState(localStorage.getItem('savedHtmlContent') || ''); // Load from localStorage initially
  
  const [header, setHeader] = useState('');
  const [footer, setFooter] = useState('');

  useEffect(() => {
    const jsonData = location.state?.jsonData;
    if (jsonData) {
      if (jsonData.markdown) {
        const markdownToHtml = (
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
              span: ({ node, ...props }) => (
                <span
                  {...props}
                  style={{ float: props.className === 'duration' ? 'right' : 'none', whiteSpace: 'nowrap', display: 'inline-block', width: 'auto' }}
                />
              ),
            }}
          >
            {jsonData.markdown}
          </ReactMarkdown>
        );
        setHtmlContent(renderToString(markdownToHtml));
      }
      setHeader(jsonData.header || '');
      setFooter(jsonData.footer || '');
    }
  }, [location.state]);

  const handleHtmlEdit = (value) => {
    setHtmlContent(value);
  };

  const handleConvertToHTMLAndSend = async () => {
    try {
      
      const quillInstance = quillRef.current.getEditor();
      const delta = quillInstance.getContents();
      const converter = new QuillDeltaToHtmlConverter(delta.ops, {
        inlineStyles: {
          font: (value) => `font-family: ${value};`,
          size: (value) => {
            switch (value) {
              case 'small': return 'font-size: 10px;';
              case 'large': return 'font-size: 18px;';
              case 'huge': return 'font-size: 24px;';
              default: return 'font-size: 14px;';
            }
          },
          color: (value) => `color: ${value};`,
          background: (value) => `background-color: ${value};`,
          bold: () => 'font-weight: bold;',
          italic: () => 'font-style: italic;',
          underline: () => 'text-decoration: underline;',
          strike: () => 'text-decoration: line-through;',
          script: (value) =>
            value === 'sub' ? 'vertical-align: sub; font-size: smaller;' : 'vertical-align: super; font-size: smaller;',
        },
      });
      let html = converter.convert();
      html = `<head> <link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet"> <style> body { } h1, h3, h4, h5, h6 { margin: 0; } p { margin: 0; } ul { margin: 0; padding-left: 30px;} h2 { margin-top: 0.5em; margin-bottom: 0.5em; } </style> </head> <div style="font-family: sans-serif; font-size: 14px;  white-space: pre-wrap;">${html}</div>`;

      // Save the generated HTML to localStorage
      setHtmlContent(html);
      localStorage.setItem('savedHtmlContent', html);
 
      const formData = new FormData();
      formData.append('test', JSON.stringify({
        request_id: location.state?.jsonData?.request_id || '',
        html_content: html,
        header_html: `<html> <body style="position: relative; min-height: 100vh; border-bottom: 0px solid grey;margin: 0; padding: 0; box-sizing: border-box;"> <div style="position: absolute; bottom: 0; width: 100%; text-align: center;  padding: 15px 0;"> <div style="height: 40px; display: flex; align-items: flex-end; justify-content: center;"> <img src="${header}" style="height: 100%; max-width: 100%; object-fit: contain;" alt="Header Logo"> </div> </div> </body> </html>`,
        footer_html: `<html> <body style="text-align: center; width: 100%; border-top: 0px solid grey;"> <img src="${footer}" style="height: 40px; padding: 10px; width: auto;" alt="Footer Logo"> </body> </html>`,
        mode: 'generate_pdf',
      }));
     
      const response = await axios.post(
        API_URL,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          responseType: 'blob',
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = window.URL.createObjectURL(blob);
        navigate('/pdf-viewer', { state: { pdfUrl } });
      } else {
        console.error('Error: Unexpected response status', response.status);
      }
    } catch (error) {
      console.error('Error posting HTML content:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" flexDirection="column" height="100%" alignItems="center" justifyContent="flex-start" p={2}>
        <EditorContainer>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <IconButton onClick={() => navigate('/')} color="primary">
              <HomeIcon />
            </IconButton>
            <Button variant="contained" color="primary" onClick={handleConvertToHTMLAndSend}>
              Generate Pdf
            </Button>
          </Box>

          <Typography variant="h6" gutterBottom textAlign="center">
            Resume Text Editor
          </Typography>

          <Box sx={{ bgcolor: 'background.paper', padding: 2, borderRadius: 2 }}>
            <ReactQuill
              ref={quillRef}
              value={htmlContent}
              onChange={handleHtmlEdit}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ size: ['small', false, 'large', 'huge'] }],
                  ['bold', 'italic', 'underline', 'strike'],
                  [{ color: [] }, { background: [] }],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ align: [] }],
                  ['clean'],
                ],
                imageResize: { displaySize: true },
              }}
              theme="snow"
              style={{ fontFamily: 'sans-serif', fontSize: '14px', width: '100%', height: '100%' }}
            />
          </Box>
        </EditorContainer>
      </Box>
    </ThemeProvider>
  );
};

export default RichTextHTMLViewer;
